<template>
  <b-modal
    id="edit-groups-file-modal"
    hide-header
    hide-footer
    centered
    @hidden="closeModal"
    size="lg"
  >
    <div class="header">
      <div class="header-content">
        <p>Editar grupo de arquivos anexado</p>
        <Close class="close-icon" @click="closeModal" />
      </div>
    </div>

    <div class="body">
      <div class="body-content">
        <b-row class="mt-3 ml-3">
          <b-col cols="8">
            <b-form-group
              label="Nome do grupo"
              label-for="group-name"
              label-class="label"
            >
              <b-form-input
                id="group-name"
                v-model="groupName"
                placeholder="Nome do grupo"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="forms.length" class="show-file">
          <FileContainer
            :forms="forms"
            :isGroupedFiles="true"
            :removeFile="removeFile"
            :changeName="changeName"
            @update-forms="forms = $event"
          />
        </div>

        <div
          v-else
          class="set-file"
          @drop="addFiles"
          @dragover.prevent
          @drop.prevent
        >
          <div class="set-file-content">
            <p class="set-file-content-text">
              Arraste e solte os arquivos aqui
            </p>
            <div class="middle-set-file-content">
              <div class="middle-set-file-content-detail" />
              <p class="middle-set-file-content-text">ou</p>
              <div class="middle-set-file-content-detail" />
            </div>
            <label for="set-file-content-input" class="set-file-content-input">
              Anexar arquivos
            </label>
            <input
              multiple
              id="set-file-content-input"
              name="set-file-content-input"
              type="file"
              accept=".jpeg, .jpg, .png, .pdf, .tiff"
              @change="addFiles"
            />
          </div>
        </div>
        <div class="send-button">
          <b-button
            variant="primary"
            :loading="loading"
            :disabled="loading"
            @click="uploadFiles"
          >
            <b-spinner v-if="loading" small variant="light" />
            Salvar grupo
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import attendanceFileMixin from '@/mixins/attendanceFileMixin'

export default {
  name: 'EditGroupsFileModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
  },
  props: {
    patientId: String,
    getAllExams: Function,
    getAllDocs: Function,
    document: Object,
    group: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      loading: false,
      sent: false,
      forms: [],
      isValidated: true,
      groupName: '',
      groupId: null,
      ids: []
    }
  },
  mixins: [attendanceFileMixin('forms', 'groupName', 'groupFiles')],
  methods: {
    closeModal() {
      this.onHidden()
      this.$emit('clearDocument')
      this.$bvModal.hide('edit-groups-file-modal')
    },
    onHidden() {
      this.forms = []
      this.sent = false
      this.groupId = null
      this.groupName = ''
      this.ids = []
    },
    addFiles(e) {
      const forms = []
      const files = e.target.files || e.dataTransfer.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const type = file.type.split('/')[1]
        if (
          ![
            'pdf',
            'jpg',
            'jpeg',
            'png',
            'application/pdf',
            'image/jpg',
            'image/jpeg',
            'image/png',
            'tiff'
          ].includes(type)
        ) {
          return this.$toast.error('Formato de arquivo não suportado!')
        }
        forms.push({
          file,
          type,
          type_id: 'intern',
          custom_name: file.name,
          error: false,
          progress: 0,
          procedure_id: null
        })
      }
      this.forms = forms
    },
    async uploadFiles() {
      if (!this.isValid()) return
      this.loading = true
      for (let index = 0; index < this.forms.length; index++) {
        const form = this.forms[index]
        const customName = this.suitName(form.custom_name)
        const dataForm = new FormData()

        dataForm.append(`file`, form.file)
        dataForm.append(`custom_name`, customName)
        dataForm.append(`type_id`, form.type_id)
        dataForm.append('distinct_type', this.document.distinct_type)
        dataForm.append('person_id', this.patientId)
        dataForm.append('clinic_id', this.clinic.id)
        dataForm.append('position', form.position)

        dataForm.append('group_name', this.groupName)
        dataForm.append('group', this.groupId)
        try {
          if (this.group[index]) {
            const response = await this.api.updateExamFile(
              this.group[index].id,
              dataForm,
              {
                onUploadProgress: event => {
                  form.progress = Math.round((event.loaded * 100) / event.total)
                }
              }
            )
            this.ids.push(response.data.id)
          } else {
            const response = await this.api.createExamFile(dataForm, {
              onUploadProgress: event => {
                form.progress = Math.round((event.loaded * 100) / event.total)
              }
            })
            this.ids.push(response.data.id)
          }
        } catch (err) {
          form.error = err.message
          this.$toast.warning(`Não foi possível enviar o arquivo ${customName}`)
        }
      }
      await this.deleteUnusedFiles()
      this.loading = false
      this.sent = true
      this.$toast.success('Envios finalizados')
      await this.updateDocumentList()
    },
    async deleteUnusedFiles() {
      try {
        await this.api.deleteUnusedFiles(this.ids)
      } catch (error) {
        this.$toast.error('Não foi possível deletar os arquivos não utilizados')
      }
    },
    async updateDocumentList() {
      if (this.document.distinct_type === 'exam') {
        await this.getAllExams()
      } else {
        await this.getAllDocs()
      }
    },
    isValid() {
      let valid = true
      if (!this.forms.length) {
        this.$toast.warning('Selecione pelo menos dois arquivos')
        this.isValidated = false
        valid = false
      } else if (!this.validatedSameTypeFiles()) {
        this.$toast.warning(
          'Não é possível agrupar arquivos de tipos diferentes'
        )
        valid = false
      }
      return valid
    },
    validatedSameTypeFiles() {
      const types = this.forms.map(form => {
        if (form.file.type.includes('image')) {
          return 'image'
        } else {
          return form.file.type
        }
      })
      const uniqueTypes = [...new Set(types)]
      return uniqueTypes.length === 1
    }
  },
  watch: {
    document: {
      handler(value) {
        if (value) {
          this.groupName = value.group_name
          this.groupId = value.group
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#edit-groups-file-modal {
  border-radius: 8px;
  .modal-content {
    .modal-body {
      padding: 0;
      .header {
        border-bottom: 1px solid var(--neutral-200);
        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px 24px;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }
          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            padding: 24px;

            .title-body-content {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
            }

            .input-name-file {
              background: #ffffff;
              border: 1px solid #c6ceeb;
              box-sizing: border-box;
              border-radius: 8px;
              height: 56px;
              outline: none;
              text-indent: 16px;
              color: var(--type-active);
              margin: 8px 0 24px 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
            }

            .file-content {
              display: flex;
              flex-direction: column;

              .title-file-content {
                color: var(--dark-blue);
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
              }

              .file-type {
                display: flex;
                align-items: center;

                .input-file-type {
                  height: 24px;
                  width: 24px;
                  margin-right: 12px;
                  box-sizing: border-box;
                  border-radius: 100px;
                  cursor: pointer;
                }

                p + input {
                  margin-left: 24px;
                }
              }
            }
          }

          .show-file {
            border-top: 1px solid #c6ceeb;
            border-bottom: 1px solid #c6ceeb;

            .show-file-content {
              display: flex;
              flex-direction: column;
              margin: 16px 24px;
              border: 1px solid var(--neutral-200);
              border-radius: 8px;
              padding: 10px;

              .file-content-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .data-file {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 10px;

                  .pdf-icon {
                    width: 42px !important;
                    height: 42px !important;
                    margin-left: -5px;
                  }

                  .jpg-icon {
                    width: 42px !important;
                    height: 42px !important;
                    margin-left: -5px;
                  }

                  .png-icon {
                    width: 42px !important;
                    height: 42px !important;
                    margin-left: -5px;
                  }

                  #data-file-text {
                    margin-left: 16px;
                    width: 40ch;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .close-icon {
                  height: 24px;
                  width: 24px;
                  fill: var(--neutral-500);
                }
              }

              .laudable-forms {
                padding-top: 15px;
              }
            }
          }

          .set-file {
            margin: 24px;
            .set-file-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 300px;
              border: 1px dashed #305bf2;
              border-radius: 8px;

              .set-file-content-text {
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
              }

              .middle-set-file-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 12px 0;

                .middle-set-file-content-detail {
                  width: 54px;
                  height: 2px;
                  background: var(--neutral-300);
                  border-radius: 16px;
                }

                .middle-set-file-content-text {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 130%;
                  padding: 0 14px;
                }
              }

              .set-file-content-input {
                width: auto;
                height: auto;
                padding: 8px 16px;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                color: var(--neutral-000);
                border-radius: 8px;
                background: var(--blue-700);
                cursor: pointer;
              }

              #set-file-content-input {
                display: none;
              }
            }
          }
        }
      }

      .footer {
        .footer-content {
          display: flex;
          justify-content: right;
          margin: 24px;

          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            border-radius: 8px;
            background-color: var(--blue-500);
            color: var(--neutral-000);
          }
        }
      }
    }
  }

  .file-name-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: var(--type-placeholder);
    }
  }
  .edit-label {
    font-size: 12px;
    font-weight: 600;
    color: var(--type-placeholder);
  }
  .progress {
    margin: 5px 24px;
  }
  .send-button {
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }
  .right-options {
    display: flex;
    align-items: center;
    gap: 10px;

    .laudable-check {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-size: 14px;
        font-weight: 600;
        color: var(--type-active);
      }
    }
  }
  .file-type {
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;

    .input-file-type {
      height: 24px;
      width: 24px;
      margin-right: 12px;
      box-sizing: border-box;
      border-radius: 100px;
      cursor: pointer;
    }

    p + input {
      margin-left: 24px;
    }
  }
}
</style>
