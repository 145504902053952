const attendanceFileMixin = () => ({
  methods: {
    removeFile(index) {
      const forms = [...this.forms]
      forms.splice(index, 1)
      this.forms = forms
    },
    changeName(index, value) {
      this.$set(this.forms[index], 'custom_name', value)
      this.$set(this.forms[index], 'canEdit', false)
    },
    validatedSameTypeFiles() {
      const types = this.forms.map(form => {
        if (form.file.type.includes('image')) {
          return 'image'
        } else {
          return form.file.type
        }
      })
      const uniqueTypes = [...new Set(types)]
      return uniqueTypes.length === 1
    },
    changeGroupName(name) {
      this.groupName = name
    },
    changeGroup(value) {
      this.groupFiles = value
    },
    suitName(name) {
      return name
        .replace(/\.[0-9a-z]+$/i, '')
        .replace(/[^\w\sA-Za-zÀ-ÖØ-öø-ÿ-()]/g, '')
    },
  },
});


export default attendanceFileMixin;
